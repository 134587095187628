import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Page1, Page2, Page3 } from "./Pages";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Page1 />} />
                <Route path="/accessories" element={<Page2 />} />
                <Route path="/books" element={<Page3 />} />
            </Routes>
        </Router>
    );
};

export default App;
